import { Controller } from "@hotwired/stimulus"
import { csrfToken } from "utils/csrf_token";

// This controller handles the onboardee clicking a "perform an action" action
export default class extends Controller  {
  static targets = ["actionTriggerPhrase", "todoIcon", "doneIcon"]

  static values = {
    url: String,
    method: String
  }

  call(event) {
    event && event.preventDefault();

    if (this.element.dataset.done) {
      return;
    } else {
      this.addPerformingStatus();

      this.performRequest().then(response => {
        this.removePerformingStatus();
        this.markAsDone();
        this.removeAction();
      });
    }
  }

  async performRequest() {
    fetch(this.urlValue, {
      method: this.methodValue,
      headers: {
        'X-CSRF-Token': csrfToken(),
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
    .then(response => response.json())
  }

  addPerformingStatus() {
    this.element.dataset.performing = true;
  }

  removePerformingStatus() {
    delete this.element.dataset.performing;
  }

  markAsDone() {
    this.element.dataset.done = true;
    this.actionTriggerPhraseTarget.remove();
    this.todoIconTarget.classList.add("d-none");
    this.doneIconTarget.classList.remove("d-none");
  }

  removeAction() {
    window.updateBoardeeActions(this.element);
  }
}
